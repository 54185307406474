

.form_group{
    margin-bottom: 10px;
    input {
        border: 1px solid lightgray;
        border-radius: 4px;
        padding: 7px;
        width: 100%;
        outline: none;

    }
    
}

.form-block{
    background-color: #fff;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    padding: 25px 30px;
    margin: auto;
}