.alternate-row {
    background-color: #f2f2f2; /* Set the alternate gray color here */
  }
  .sc-dPwPAC.fkNzmq.rdt_TableBody {
    max-height: 500px;
}

.rule_type {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 40px !important;
}