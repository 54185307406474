.change_password{
    .form_group {
        position: relative;
        svg {
            position: absolute;
            top: 35px;
            right: 13px;
            cursor: pointer;
        }
    }
    
    
}