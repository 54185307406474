
$dark-blue:#293042;
// $blue-text:#568fed;
$blue-text:#0a2351;
$gray-text:#adb5bd;
$body-bg:#f7f9fc;
$lite-bg:#fff;
$heading-text:#495057;
// $primary-bg:#568fed;
$white:#fff;
$primary-bg:#0a2351;
