.campaignName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #2e8bc0;
    cursor: pointer;
}
.tblHead {
    font-size: 15px;
    color: black;
}
.tblSubHead {
    font-size: 14px;
    color: gray;
}
.date {
    display: flex;
    border: 1px dotted black;
    background-color: #d3d3d3;
}
.dottedBox {
    display: flex;
    border: 1px dotted black;
}
.budgetBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.ended {
    color: rgb(80, 81, 80);
}
.enable {
    color: green;
}
.pause {
    color: red;
}
.archived {
    color: blue;
}
// [data-column-id="Active"].rdt_TableCol {
//     position: sticky;
//     left: 0;
//     z-index: 1
//   }
//   [data-column-id="id"].rdt_TableCol {
//     position: sticky;
//     left: 100px;
//     width: 250px;
//     z-index: 1
//   }
//   [data-column-id="name"].rdt_TableCol {
//     position: sticky;
//     width: 350px;
//     left: 250px;
//     z-index: 1
//   }

//   search //
.campaign_search {
    position: relative;
    .text_search {
        border: 1px solid lightgray;
        border-radius: 5px;
        padding: 10px;
        width: 100%;
        outline: none;
        padding-left: 18px;
        padding-right: 67px;
        font-size: 14px;
        line-height: 13px;
        margin-bottom: 0;
    }

    .search_go {
        position: absolute;
        right: 13px;
        height: 100%;
        background: $primary-bg;
        padding: 0 17px;
        color: white;
        border: none;
        font-size: 15px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.campaign_boxes {
    display: flex;
    justify-content: space-around;
    margin-bottom: 14px;

    .inner-box {
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        display: grid;
        justify-content: center;
        text-align: center;
        padding: 6px 0;
    }
    span {
        color: #0a2351;
        font-weight: 600;
    }
}
